type In = {
  email: string
  startDate: string
  endDate: string
  page: number
}

type Out = {
  email: string
  start_date: string
  end_date: string
  page: number
}

export default function serializeOnboardingSearch(params: In): Out {
  return {
    email: params.email,
    start_date: params.startDate,
    end_date: params.endDate,
    page: params.page,
  }
}
