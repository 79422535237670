import { useEffect, useRef } from 'react'

// Source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
const useInterval = (callback: (...args: Array<any>) => any, delay: number) => {
  const savedCallback = useRef<null | ((...args: Array<any>) => any)>(null)

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }

    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default useInterval
