import {
  AFFIRM_CDN_HOST,
  AFFIRM_MINIMUM_PAYMENT,
  AFFIRM_PUBLIC_API_KEY_DEFAULT,
} from '~/config'
import { isAffirmEnabled } from '~/utils/affirm'

import { AffirmConfigStrategySelectorArguments } from './affirm-config.types'
import { isAddressInUS } from './affirm-config.utils'

export const globalStrategyDefault = {
  matches: ({
    billingAddress,
    cart,
  }: AffirmConfigStrategySelectorArguments) => {
    if (!isAffirmEnabled()) return false
    if (billingAddress && !isAddressInUS(billingAddress)) return false
    if (cart.total < AFFIRM_MINIMUM_PAYMENT) return false
    if (cart.purchaseGroup !== 'default') return false
    if (cart.payloadCurrency !== 'USD') return false

    return true
  },
  config: (_: AffirmConfigStrategySelectorArguments) => ({
    public_api_key: AFFIRM_PUBLIC_API_KEY_DEFAULT,
    script: `${AFFIRM_CDN_HOST}/js/v2/affirm.js`,
    country_code: 'USA',
    locale: 'en_US',
  }),
}
