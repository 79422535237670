import React from 'react'

import { i18n } from '~/i18n'

import './not-found-message.scss'
import Button from '../button'

type Props = {
  error?: string
  linkText?: string
  linkUrl?: string
  body?: string
  title?: string
}

export default class NotFoundMessage extends React.PureComponent<Props> {
  static defaultProps = {
    linkUrl: '/login',
  }

  // these can't go in default props because translations are not set until after
  // this module is loaded:
  get defaultContent() {
    return {
      error: i18n.t('components.not_found_message.error'),
      linkText: i18n.t('components.not_found_message.link_text'),
      body: i18n.t('components.not_found_message.body'),
      title: i18n.t('components.not_found_message.title'),
    }
  }

  render() {
    const props = { ...this.defaultContent, ...this.props }
    const { error, linkText, linkUrl, body, title } = props
    return (
      <section className="not-found-message">
        <h1 className="not-found-message-title">{title}</h1>
        <p className="not-found-message-error">{error}</p>
        <p className="not-found-message-body">{body}</p>
        <Button to={linkUrl} type="progress">
          {linkText}
        </Button>
      </section>
    )
  }
}
