import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createSelector as originalCreateSelector } from 'reselect'

const createSelector = (selector): typeof selector =>
  originalCreateSelector(stateSelector, selector)
const stateSelector = (state) => state
const paramsSelector = (_, params) => params
const createParameterSelector = (selector): typeof selector =>
  originalCreateSelector(stateSelector, paramsSelector, selector)

const sortValuesByKey = (params) =>
  Object.keys(params)
    .sort()
    .map((key) => params[key])

/**
 * Takes a state selector function, with or without custom parameters, and memoizes it.
 *
 * CAVEAT: If any of the custon parameters are non-primitive, memoization will not occur.
 * This hook is intended to be used for route-specific parameters such as: entity IDs,
 * feature flags, browser location values, etc. If you are passing non-primitive values,
 * you should rethink your pattern.
 *
 * @param selector The selector function (e.g. mapStateToProps) - This function memory reference must
 * remain stable throughout the component lifecycle.
 * @param {object} params The parameters passed as a 2nd arg to the selector function. MAY ONLY CONTAIN
 * PRIMITIVE VALUES (or any non-primitive values must be cached in the params object manually).
 * Otherwise, memoization will not occur.
 *
 * @return {object} The cached result of the selector function when executed.
 */
export default (selector, params = {}) => {
  const memoizedParams = useMemo(() => params, [...sortValuesByKey(params)])
  const memoizedSelector = useMemo(
    () =>
      memoizedParams
        ? createParameterSelector(selector)
        : createSelector(selector),
    [memoizedParams, selector],
  )

  return useSelector((state) =>
    memoizedParams
      ? memoizedSelector(state, memoizedParams)
      : memoizedSelector(state),
  )
}
