import { decamelizeKeys } from 'humps'

import type { PricingWindow, Variant } from '~/admin/types'

const serializePricingWindowVariant = (variant: Variant) => {
  const { maxAge, priceUsd, priceCad, ...rest } = variant
  return decamelizeKeys({
    maxAge: maxAge === '' ? null : maxAge,
    priceUsd: priceUsd === '' ? null : priceUsd,
    priceCad: priceCad === '' ? null : priceCad,
    ...rest,
  })
}

export const serializePricingWindow = (fields: PricingWindow) => {
  const { startDate, startTime, endDate, endTime, variants } = fields
  return {
    active_from: !startDate ? '' : `${startDate}T${startTime}:00`,
    active_to: !endDate ? '' : `${endDate}T${endTime}:00`,
    pricing_window_variants_attributes: variants.map<Record<string, any>>(
      (variant: Variant) => serializePricingWindowVariant(variant),
    ),
  }
}
