import { creditName } from '~/utils/credit'
import fullName from '~/utils/full-name'
import getFeatureFlags from '~/utils/get-feature-flags'

import type { Cart, GuestProfile, Address, Currency } from '~/types'

type AffirmAddress = {
  address: AffirmPostalAddress
  name: {
    first?: string
    last?: string
    full?: string
  }
  email: string
}

type AffirmPostalAddress = {
  street1: string
  city: string
  region1_code: string
  postal_code: string
  country: 'CAN' | 'USA'
}

type AffirmItem = {
  display_name: string
  sku: string
  unit_price: number
  qty: number
  item_image_url: string
  item_url: string
}

type AffirmCheckoutData = {
  shipping?: AffirmAddress
  billing: AffirmAddress
  items: AffirmItem[]
  discounts: Record<
    string,
    {
      discount_amount: number
      discount_display_name: string
    }
  >
  metadata: {
    mode: 'modal'
  }
  currency: Currency
  shipping_amount: number
  tax_amount: number
  total: number
}

export function buildCheckoutData(
  cart: Cart,
  profile: GuestProfile,
): AffirmCheckoutData {
  const billingAddress = profile.addresses.find(
    ({ type }) => type === 'billing',
  )
  if (!billingAddress) {
    throw 'Cannot build checkout object without billing address'
  }

  const affirmCheckoutObject: AffirmCheckoutData = {
    billing: buildAddress(billingAddress, profile),
    items: buildItems(cart),
    discounts: buildDiscounts(cart),
    metadata: {
      mode: 'modal',
    },
    currency: cart.payloadCurrency,
    shipping_amount: 0,
    tax_amount: cart.taxTotal || 0,
    total: cart.total,
  }

  const shippingAddress = profile.addresses.find(
    ({ type }) => type === 'shipping',
  )

  // We should not supply the shipping address for Blue & Tremblant orders with Affirm
  if (cart.purchaseGroup === 'default') {
    affirmCheckoutObject.shipping = buildAddress(shippingAddress, profile)
  }

  return affirmCheckoutObject
}

function buildAddress(address: Address, profile: GuestProfile) {
  const affirmAddress = {
    street1: address.street1,
    city: address.city,
    region1_code: address.stateProvinceCode,
    postal_code: address.zipPostal,
    country: address.countryCode === 'CA' ? 'CAN' : 'USA',
  } as AffirmPostalAddress

  return {
    name: {
      first: profile.firstName || '',
      last: profile.lastName || '',
      full: fullName(profile.firstName, profile.lastName),
    },
    email: profile.email,
    address: affirmAddress,
  }
}

function buildItems(cart: Cart) {
  const items = cart.profiles.flatMap((profile) => profile.items)

  const serializedItems = []

  items.forEach((item) => {
    const matchingSerializedItemIndex = serializedItems.findIndex(
      (serializedItem) =>
        serializedItem.display_name === item.title &&
        serializedItem.sku === item.type &&
        serializedItem.unit_price === item.originalPrice,
    )

    if (matchingSerializedItemIndex !== -1) {
      serializedItems[matchingSerializedItemIndex].qty += 1
    } else {
      serializedItems.push({
        display_name: item.title || '',
        sku: item.type,
        unit_price: item.originalPrice,
        qty: 1,
        item_image_url: '',
        item_url: '',
      })
    }
  })

  return serializedItems
}

function buildDiscounts(cart: Cart) {
  const discounts = {}
  const items = cart.profiles.flatMap((profile) => profile.items)

  items.forEach((item) => {
    if (item.discount && Object.keys(item.discount).length) {
      const discountCode = item.discount.categoryCode

      if (discounts[discountCode]) {
        discounts[discountCode].discount_amount += item.discount.amount
      } else {
        discounts[discountCode] = {
          discount_amount: item.discount.amount,
          discount_display_name: item.discount.title,
        }
      }
    }

    if (item.credit) {
      const discountCode = item.credit.kind || ''

      if (discounts[discountCode]) {
        discounts[discountCode].discount_amount += item.credit.amountApplied
      } else {
        discounts[discountCode] = {
          discount_amount: item.credit.amountApplied,
          discount_display_name: creditName(item.credit),
        }
      }
    }
  })

  return discounts
}

export const isAffirmEnabled = () => {
  const { affirmEnabled } = getFeatureFlags()

  return affirmEnabled && Boolean(getAffirmAPI())
}

export const getAffirmAPI = () => window.affirm
