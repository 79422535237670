import React from 'react'

import skiierIconUrl from '~/graphics/icons/skiier.svg'
import { passImageURL } from '~/utils/pass-image'

import { Wrapper, StyledImage } from './profile-picture.styles'

import type { CloudinaryPhoto } from '~/types'

export type Props = {
  alt: string
  blankURL?: string
  className?: string
  photo?: CloudinaryPhoto | null | undefined
  id?: string
}

const Image = (props) => {
  const imgURL = props.photoURL || props.placeholderURL

  return (
    <StyledImage
      src={imgURL}
      alt={props.alt}
      $isSkiierPlaceholder={props.$isSkiierPlaceholder}
    />
  )
}

const ProfilePicture = (props: Props) => {
  const placeholderURL = props.blankURL || skiierIconUrl

  const photo = props.photo
  const photoURL = photo && photo.publicId ? passImageURL(photo) : null

  const isSkiierPlaceholder = !photoURL && !props.blankURL

  return (
    <Wrapper
      id={props.id}
      data-testid="profile-pic-wrapper"
      className={props.className}
      $isSkiierPlaceholder={isSkiierPlaceholder}
    >
      <Image
        photoURL={photoURL}
        placeholderURL={placeholderURL}
        alt={props.alt}
        $isSkiierPlaceholder={isSkiierPlaceholder}
      />
    </Wrapper>
  )
}

export default ProfilePicture
