import { i18n } from '~/i18n'
import { creditName } from '~/utils/credit'

import type {
  Cart,
  CartState,
  CartItem,
  CartParticipant,
  ComputedCartParticipant,
  GroupMember,
  Product,
  ProductCounts,
} from '../types'
import type { PricingDetail } from '~/components/order-item/order-item.types'

export function allItems(cart: CartState): CartItem[] {
  return cart.profiles.reduce((acc, profile) => acc.concat(profile.items), [])
}

export function computedCartParticipants(
  profiles: CartParticipant[],
  groupMembers: GroupMember[],
): ComputedCartParticipant[] {
  return profiles.map((profile) => {
    const groupMember = groupMembers.find(({ id }) => profile.id === id)
    const computedProfile = {
      ...profile,
      firstName: '',
      lastName: '',
      dob: '',
      cloudinary: {},
      email: '',
    }

    if (groupMember) {
      computedProfile.firstName = groupMember.firstName
      computedProfile.lastName = groupMember.lastName
      computedProfile.dob = groupMember.dob
      computedProfile.cloudinary = groupMember.cloudinary
      computedProfile.email = groupMember.email
    }

    return computedProfile
  })
}

export function itemCount(cart: CartState) {
  const items = allItems(cart)
  return items.length
}

export function allTickets(cart: CartState): CartItem[] {
  return allItems(cart).filter((item) => item.type === 'day_ticket')
}

export function hasOnlyTickets(cart: CartState) {
  const cartItemCount = allItems(cart).length
  const cartTicketCount = allTickets(cart).length
  return cartItemCount !== 0 && cartItemCount === cartTicketCount
}

export function getTotalPrice(item: CartItem): number {
  const addons = getAddons(item)
  const addonsTotal = addons.reduce((acc, addon) => acc + addon.amount, 0)

  const creditAmount = item.credit?.amountApplied || 0

  return item.paidPrice + addonsTotal - creditAmount
}

export function getSavingsDetail(
  item: CartItem,
): PricingDetail | null | undefined {
  const discount = item.discount

  if (discount && discount.amount) {
    return {
      description: discount.title || '',
      amount: -discount.amount,
    }
  }
}

export function getCredit(item: CartItem): PricingDetail | null | undefined {
  const credit = item.credit

  if (credit) {
    return {
      description: i18n.t('credits.discount_line', {
        creditName: creditName(credit),
      }),
      amount: -credit.amountApplied,
    }
  }
}

export function getInsurance(item: CartItem) {
  const addons = getAddons(item)
  return addons.find(({ description }) => description === 'insurance')
}

export const allProductsInsured = (
  cart: Cart,
  productsWithEditableInsurance: Product[],
) => {
  const items = allItems(cart)
  const insuranceChangeItems = items.filter((item) => {
    return Boolean(
      productsWithEditableInsurance.find((product) => item.type === product.id),
    )
  })
  const insurances = insuranceChangeItems.map(getInsurance)
  return insurances.every((insurance) => insurance !== undefined)
}

export function getAddons(item: CartItem): Array<PricingDetail> {
  return (item.addons || []).reduce((acc, { product, price }) => {
    if (price == null) {
      return acc
    }

    acc.push({
      description: `${product}`,
      amount: price,
    })
    return acc
  }, [])
}

export function getPricingDetails(item: CartItem): Array<PricingDetail> {
  const pricingDetails = []

  const discount = getSavingsDetail(item)
  if (discount) pricingDetails.push(discount)

  const credit = getCredit(item)
  if (credit) pricingDetails.push(credit)

  return pricingDetails
}

export function decorateItem(item: CartItem) {
  const restrictionResorts =
    (i18n.t(`products.${item.type}.restriction_resorts`, {
      defaultValue: '',
    }) as unknown as string[]) || undefined
  const restrictionWarning =
    i18n.t(`products.${item.type}.restriction_warning`, {
      defaultValue: '',
    }) || undefined

  return {
    ...item,
    title: i18n.t(`products.${item.type}.title`),
    displayName: i18n.t(`product_variants.${item.variant}.display_name`),
    displayAgeRange: displayAgeRange(item.ageRange.min, item.ageRange.max),
    restrictionResorts,
    restrictionWarning,
  }
}

export function displayAgeRange(min: number, max: number | null | undefined) {
  if (min === 0 && max === null) return ''
  if (!max) return `(${i18n.t('product_variants.age')} ${min}+)`
  return `(${i18n.t('product_variants.ages')} ${min}-${max})`
}

export function cartWithProductNames(cart: CartState): CartState {
  const profiles = cart.profiles.map((profile) => {
    const items = profile.items.map((item) => decorateItem(item))
    return { ...profile, items }
  })
  return {
    ...cart,
    profiles,
  }
}

export function productCounts(
  cart: CartState | null | undefined,
  products: Product[],
): ProductCounts {
  const types = products.reduce(function (acc, current) {
    acc[current.id] = {}
    return acc
  }, {})

  const items = cart ? allItems(cart) : []
  return items.reduce((totals, { type, variant }) => {
    if (!totals[type]) totals[type] = {}
    totals[type][variant] = (totals[type][variant] || 0) + 1
    return totals
  }, types)
}

export const hasUpgradeProducts = (cart: CartState, products: Product[]) => {
  return allItems(cart).some((item) =>
    products.some((product) => product.id === item.type && product.isUpgrade),
  )
}

export const hasOnlyUpgradeProducts = (
  cart: CartState,
  products: Product[],
) => {
  return allItems(cart).every((item) =>
    products.some((product) => product.id === item.type && product.isUpgrade),
  )
}

export function orderedItemsFromProfile(
  profiles: CartParticipant[],
  products: Product[],
): { item: CartItem; profile: CartParticipant }[] {
  return profiles
    .reduce((items, profile) => {
      profile.items.forEach((item) => items.push({ item, profile }))
      return items
    }, [])
    .sort((rawA, rawB) => {
      return (
        parseInt(rawA.item.internalId.replace(/\D/g, '')) -
        parseInt(rawB.item.internalId.replace(/\D/g, ''))
      )
    })
    .sort((rawA, rawB) => {
      const productA = products.find(({ id }) => id === rawA.item.type)
      const productB = products.find(({ id }) => id === rawB.item.type)
      if (!productA || !productB) return 0
      return productA.addOnForProducts.length - productB.addOnForProducts.length
    })
}

export function waiverSignedForAllParticipants(cart: CartState): boolean {
  return cart.profiles.every((profile) => {
    return profile.waiversRequired.every((waiverID) =>
      waiverSignedForProfile(waiverID, profile),
    )
  })
}

export function accessRestrictions(cart: CartState): Record<string, string[]> {
  return allItems(cart).reduce(
    (acc, cartItem) =>
      cartItem.title && cartItem.restrictionResorts
        ? Object.assign(acc, {
            [cartItem.title]: cartItem.restrictionResorts,
          })
        : acc,
    {},
  )
}

export function waiverSigned(waiverID: number, cart: CartState) {
  return cart.profiles.every((profile) => {
    if (profile.waiversRequired.includes(waiverID))
      return waiverSignedForProfile(waiverID, profile)
    return true
  })
}

function waiverSignedForProfile(waiverID: number, profile: CartParticipant) {
  const { waiversSigned } = profile
  if (!waiversSigned) return false
  return !!waiversSigned[waiverID]
}
