import { decamelizeKeys } from 'humps'

import type {
  ProductTranslations,
  API$ProductTranslations,
} from '~/admin/types'

export const serializeProductTranslations = ({
  restrictionResorts,
  ...rest
}: ProductTranslations): API$ProductTranslations => {
  return decamelizeKeys({
    ...replaceEmptyStringTranslationsWithNull(rest),
    restrictionResorts: {
      en: restrictionResorts.en ? restrictionResorts.en.split('\n') : null,
      fr: restrictionResorts.fr ? restrictionResorts.fr.split('\n') : null,
    },
  }) as API$ProductTranslations
}

function replaceEmptyStringTranslationsWithNull(translations) {
  return Object.keys(translations).reduce((object, key) => {
    object[key] = {
      en: translations[key]['en'] || null,
      fr: translations[key]['fr'] || null,
    }
    return object
  }, {})
}
