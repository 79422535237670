import type { DateRange } from '~/types'

export const dateRangesAreEqual = (a: DateRange, b: DateRange): boolean => {
  return a.from === b.from && a.to === b.to
}

export const byFromDateAsc = (
  a: {
    from: string
  },
  b: {
    from: string
  },
  // @ts-expect-error TS2362
) => new Date(a.from) - new Date(b.from)
