type In = {
  firstName: string
  lastName: string
  email: string
  alternateId: string
  dob: string
  page: number
}

type Out = {
  first_name: string
  last_name: string
  email: string
  alternateId: string
  dob: string
  page: number
}

export default function serializeProfileSearch(params: In): Out {
  return {
    first_name: params.firstName,
    last_name: params.lastName,
    email: params.email,
    alternateId: params.alternateId,
    dob: params.dob,
    page: params.page,
  }
}
