import compact from 'lodash/compact'

const fullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined,
) => {
  return compact([firstName, lastName]).join(' ')
}

export default fullName
