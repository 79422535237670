/**
 * PassImage helpers.
 */
import defaultURL from '~/graphics/smile-icon.svg'

import { getUploadImageUrl } from './cloudinary'

import type { CloudinaryPhoto } from '../types'

type Options = Record<string, string>
/**
 * passImageURL determines the appropriate pass image. If there is a photo
 * available it will be used. Other wise a default is returned.
 * For available transformation options see:
 * http://cloudinary.com/documentation/image_transformation_reference
 *
 * @param  {Object} item    The pass to determine the image url for.
 * @param  {Object} options Cloudinary transformation options
 * @return {String}         The image url.
 */

const defaultOptions = {
  g: 'face',
  c: 'thumb',
  w: '100',
  h: '100',
}

export function passImageURL(
  photo: CloudinaryPhoto | null | undefined,
  options: Options = {},
) {
  const urlOptions = { ...defaultOptions, ...options }
  return passImageURLBuilder(photo, urlOptions)
}

function passImageURLBuilder(photo, options) {
  const urlOptions = optionsString(options)

  if (photo && photo.publicId && photo.version) {
    const { publicId, version } = photo
    return (
      getUploadImageUrl() +
      `/image/upload/${urlOptions}/v${version}/${publicId}.jpg`
    )
  }

  return defaultURL
}

function optionsString(options) {
  let optionsArray = Object.keys(options).map((key) => {
    if (options[key]) {
      return `${key}_${options[key]}`
    }
  })
  optionsArray = optionsArray.filter((option) => option !== undefined)
  return optionsArray.join()
}
