import { i18n } from '~/i18n'

import type { CreditKind, SingleUseCredit } from '~/types'

export const creditName = ({ kind }: { kind: CreditKind }) => {
  return i18n.t(`credits.${kind}.name`)
}

export const adminCreditName = ({
  kind,
  name,
}: {
  kind: CreditKind
  name: string
}) => {
  if (!name)
    return creditName({
      kind,
    })
  return `${creditName({
    kind,
  })} (${name})`
}

export const getCreditMaxAmountAndCurrency = (credit: SingleUseCredit) => {
  return credit.amountCad
    ? {
        amount: credit.amountCad,
        currency: 'CAD' as const,
      }
    : {
        amount: credit.amountUsd,
        currency: 'USD' as const,
      }
}
