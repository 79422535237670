import { addDays, isAfter, isWithinInterval, parseISO } from 'date-fns'
import { createSelector } from 'reselect'

import { byFromDateAsc } from '~/utils/date-range'

import type { AppState } from '~/reducers'
import type { ProfileProduct, ProfileProductWithBlackoutDetails } from '~/types'

export const getProfileProducts = (state: AppState): ProfileProduct[] => {
  return state.profileProducts.data || []
}

const transformProfileProducts = (
  products: ProfileProduct[],
): ProfileProductWithBlackoutDetails[] => products.map(transformProfileProduct)

export const getProfileProductsWithBlackoutDetails = createSelector(
  getProfileProducts,
  transformProfileProducts,
)

function transformProfileProduct(
  profileProduct: ProfileProduct,
): ProfileProductWithBlackoutDetails {
  const today = new Date()
  const blackoutDates = profileProduct.access.flatMap(
    ({ blackoutDates }) => blackoutDates,
  )

  const nextBlackoutDate = blackoutDates
    .sort(byFromDateAsc)
    .filter(({ to }) => isAfter(parseISO(to), today))[0]

  const hasUpcomingBlackout =
    !!nextBlackoutDate &&
    isWithinInterval(parseISO(nextBlackoutDate.from), {
      start: today,
      end: addDays(today, 7),
    })

  const blackoutInEffect = blackoutDates.some(({ from, to }) =>
    isWithinInterval(today, {
      start: parseISO(from),
      end: parseISO(to),
    }),
  )

  return {
    ...profileProduct,
    nextBlackoutDate,
    hasUpcomingBlackout,
    blackoutInEffect,
  }
}

export const blackoutInEffectSelector = createSelector(
  getProfileProductsWithBlackoutDetails,
  (profileProducts) =>
    profileProducts.some(({ blackoutInEffect }) => blackoutInEffect),
)

type Ids = {
  profileProductId: number
  profileId: string
}

const getProfileProductsWithIds = (state: AppState): ProfileProduct[] =>
  state.profileProducts.data || []

const getProfileProductsWithBlackoutDetailsWithIds = createSelector(
  getProfileProductsWithIds,
  transformProfileProducts,
)

const withIds = (_: AppState, ids: Ids): Ids => ids

type GetProfileProductWithBlackoutDetails = (
  state: AppState,
  props: Ids,
) => ProfileProductWithBlackoutDetails | null | undefined

export const getProfileProductWithBlackoutDetails: GetProfileProductWithBlackoutDetails =
  createSelector(
    getProfileProductsWithBlackoutDetailsWithIds,
    withIds,
    (
      profileProducts: ProfileProductWithBlackoutDetails[],
      { profileProductId, profileId }: Ids,
    ) => {
      return profileProducts.find(
        (product) =>
          product.id === Number(profileProductId) &&
          product.profileId === profileId,
      )
    },
  )

const getCartProfileItemCodes = createSelector(
  (state) => state.cart.profiles,
  (profiles) =>
    profiles.reduce((acc, profile) => {
      if (profile.id) {
        profile.items.forEach(({ type }) =>
          acc.push({
            profileId: profile.id,
            productCode: type,
          }),
        )
      }

      return acc
    }, []),
)

export const profileProductAndCartItemCodesByProfile = createSelector(
  getCartProfileItemCodes,
  getProfileProducts,
  (cartProducts, profileProducts) =>
    [...cartProducts, ...profileProducts].reduce((acc, product) => {
      if (!acc[product.profileId]) acc[product.profileId] = []
      acc[product.profileId].push(product.productCode)
      return acc
    }, {}),
)
