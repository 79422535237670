import { Address } from '~/types'

export const isAddressInUS = (address?: Address) => {
  return (
    !!address &&
    (address.country === 'United States' || address.country === 'États-Unis')
  )
}

export const isAddressInCanada = (address?: Address) => {
  return address?.country === 'Canada'
}
