import {
  DD_RUM_CLIENT_TOKEN,
  DD_RUM_APPLICATION_ID,
  DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
  DD_RUM_SESSION_SAMPLE_RATE,
  DD_SITE,
  DD_SERVICE,
  DD_ENV,
  DD_VERSION,
} from '~/config'

import type { DefaultPrivacyLevel } from '@datadog/browser-rum'

export const DATADOG_RUM_CONFIG = {
  clientToken: DD_RUM_CLIENT_TOKEN,
  applicationId: DD_RUM_APPLICATION_ID,
  site: DD_SITE,
  service: DD_SERVICE,
  env: DD_ENV,
  version: DD_VERSION,
  sessionSampleRate: DD_RUM_SESSION_SAMPLE_RATE,
  sessionReplaySampleRate: DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input' as DefaultPrivacyLevel,
}
