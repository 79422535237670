import styled, { css } from 'styled-components'

import { color, border } from '~/styles/utils'

export const Wrapper = styled.div<{
  $isSkiierPlaceholder: boolean
}>`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: ${border('thick')};
  border-color: ${color('coolGrey')};

  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  ${({ $isSkiierPlaceholder }) =>
    $isSkiierPlaceholder &&
    css`
      text-align: center;
    `};
`

export const StyledImage = styled.img<{
  $isSkiierPlaceholder: boolean
}>`
  width: 100%;
  height: 100%;
  border-radius: 50%;

  ${({ $isSkiierPlaceholder }) =>
    $isSkiierPlaceholder &&
    css`
      width: 60%;
      border-radius: 0;
    `};
`
